<template>
  <v-container fluid>
    <v-overlay v-if="progress">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-form ref="form" v-if="about" v-model="valid" class="ma-4">
      <!-- 営業/休業設定 (曜日別) -->
      <p class="text-h3">営業/休業設定 (曜日別)</p>
      <v-card
        outlined
        class="mt-2 mb-2"
        v-for="(every, i) in tmpDaysEvery"
        :key="`every-${i}`"
      >
        <v-card-title>
          <v-row align="center">
            <v-col cols="2">
              {{ every.dayOfWeekText }}
            </v-col>
            <v-col cols="2">
              <v-select
                v-model="every.type"
                :items="businessDayTypes"
                item-value="id"
                item-text="label"
                persistent-placeholder
              >
              </v-select>
            </v-col>
            <v-col v-if="every.type !== '0'" cols="auto" class="pb-0 ml-8">
              <v-row align="center">
                <v-col cols="auto" class="">
                  <v-text-field
                    class=""
                    type="time"
                    v-model="every.hours[0].startHHMM"
                    placeholder="9:00"
                    persistent-placeholder
                    :rules="rules.required"
                  >
                    <template v-slot:label> 開始1 </template>
                  </v-text-field>
                </v-col>
                <span>～</span>
                <v-col cols="auto" class="">
                  <v-text-field
                    type="time"
                    v-model="every.hours[0].endHHMM"
                    placeholder="19:00"
                    persistent-placeholder
                    :rules="rules.required"
                  >
                    <template v-slot:label> 終了1 </template>
                  </v-text-field>
                </v-col>
                <template v-if="every.type === '2'">
                  <v-col cols="auto" class="pl-12">
                    <v-text-field
                      type="time"
                      v-model="every.hours[1].startHHMM"
                      placeholder="9:00"
                      persistent-placeholder
                      :rules="rules.required"
                    >
                      <template v-slot:label> 開始2 </template>
                    </v-text-field>
                  </v-col>
                  <span>～</span>
                  <v-col cols="auto" class="">
                    <v-text-field
                      type="time"
                      v-model="every.hours[1].endHHMM"
                      placeholder="19:00"
                      persistent-placeholder
                      :rules="rules.required"
                    >
                      <template v-slot:label> 終了2 </template>
                    </v-text-field>
                  </v-col>
                </template>
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>

      <!-- 営業/休業設定 (臨時) -->
      <v-row align="center" class="mt-12">
        <v-col cols="auto">
          <span class="text-h3">営業/休業設定 (臨時)</span>
        </v-col>
        <v-spacer />
        <v-col cols="auto">
          <v-btn color="primary" outlined @click="addDayTemporary">
            <v-icon class="mr-2">mdi-plus</v-icon>
            <span class="text-h5">追加</span>
          </v-btn>
        </v-col>
      </v-row>

      <v-card
        outlined
        class="mt-4 mb-2"
        v-for="(temp, i) in tmpDaysTemporary"
        :key="`temp${i}`"
      >
        <v-card-title>
          <v-row align="center">
            <v-col cols="2">
              <v-menu
                :ref="`menu${i}`"
                v-model="temp.show"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    type="date"
                    v-model="temp.date"
                    prepend-icon="mdi-calendar"
                    label=""
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="temp.date"
                  @input="temp.show = false"
                  locale="jp-ja"
                  :day-format="(date) => new Date(date).getDate()"
                  color="primary"
                  header-color="primary"
                  no-title
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-select
                v-model="temp.type"
                :items="businessDayTypes"
                item-value="id"
                item-text="label"
                persistent-placeholder
              >
              </v-select>
            </v-col>
            <v-col v-if="temp.type !== '0'" cols="auto" class="pb-0 ml-8">
              <v-row align="center">
                <v-col cols="auto" class="">
                  <v-text-field
                    class=""
                    type="time"
                    v-model="temp.hours[0].startHHMM"
                    placeholder="9:00"
                    persistent-placeholder
                    :rules="rules.required"
                  >
                    <template v-slot:label> 開始1 </template>
                  </v-text-field>
                </v-col>
                <span>～</span>
                <v-col cols="auto" class="">
                  <v-text-field
                    type="time"
                    v-model="temp.hours[0].endHHMM"
                    placeholder="19:00"
                    persistent-placeholder
                    :rules="rules.required"
                  >
                    <template v-slot:label> 終了1 </template>
                  </v-text-field>
                </v-col>
                <template v-if="temp.type === '2'">
                  <v-col cols="auto" class="pl-12">
                    <v-text-field
                      type="time"
                      v-model="temp.hours[1].startHHMM"
                      placeholder="9:00"
                      persistent-placeholder
                      :rules="rules.required"
                    >
                      <template v-slot:label> 開始2 </template>
                    </v-text-field>
                  </v-col>
                  <span>～</span>
                  <v-col cols="auto" class="">
                    <v-text-field
                      type="time"
                      v-model="temp.hours[1].endHHMM"
                      placeholder="19:00"
                      persistent-placeholder
                      :rules="rules.required"
                    >
                      <template v-slot:label> 終了2 </template>
                    </v-text-field>
                  </v-col>
                </template>
              </v-row>
            </v-col>
            <v-spacer />
            <v-col cols="auto">
              <v-btn text @click="removeDayTemporary(temp.tmpId)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
      <v-card v-if="tmpDaysTemporary.length == 0" outlined class="mt-2 mb-2">
        <v-card-title>
          <v-row align="center">
            <v-col align="center" class="pa-8">
              <span class="text-h4"
                >臨時営業や臨時休業があれば、ここで設定してください</span
              >
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>

      <!-- 受付状態 -->
      <!-- <p class="mt-12 text-h3">注文受付</p>
      <v-row>
        <v-col class="pt-4 pb-0" sm="3">
          <v-select
            v-model="orderAcceptStatusSelected"
            :items="orderAcceptStatusList"
            item-value="id"
            item-text="label"
            label="受付状態 (現在)"
            outlined
          >
          </v-select>
        </v-col>
      </v-row> -->

      <!-- 提供開始/終了 -->
      <!-- <v-row>
        <v-col class="pt-0" sm="3">
          <v-text-field
            type="time"
            v-model="about.business.provideStartHHMM"
            placeholder="9:00"
            persistent-placeholder
            outlined
            :rules="rules.required"
          >
            <template v-slot:label>
              提供開始<span class="ml-2 red--text">必須</span>
            </template>
          </v-text-field>
        </v-col>
        <v-col class="pt-0" sm="3">
          <v-text-field
            type="time"
            v-model="about.business.provideEndHHMM"
            placeholder="19:00"
            persistent-placeholder
            outlined
            :rules="rules.required"
          >
            <template v-slot:label>
              提供終了<span class="ml-2 red--text">必須</span>
            </template>
          </v-text-field>
        </v-col>
      </v-row> -->

      <!-- 受付自動設定 (バッチ ON/OFF) -->
      <!-- <v-row>
        <v-col class="pt-0">
          <v-checkbox
            v-model="about.business.doBatch"
            label="提供時刻に受付状態を自動更新する"
          />
        </v-col>
      </v-row> -->

      <!-- 調理時間 -->
      <p class="mt-16 text-h3">調理時間</p>
      <v-row class="mt-4">
        <v-col class="mt-4" sm="6">
          <v-slider
            v-model="about.business.cookingMinutes"
            label="調理時間 (分)"
            min="1"
            max="59"
            thumb-label="always"
          />
        </v-col>
      </v-row>

      <!-- 接頭文字 -->
      <v-row align="center">
        <v-col cols="auto">
          <span class="text-h3">注文番号</span>
        </v-col>
        <v-col cols="auto" class="pa-0">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on"> mdi-help-circle </v-icon>
            </template>
            <span>注文番号の先頭文字</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="mt-4" sm="3">
          <v-text-field
            v-model="about.orderIdPrefix"
            placeholder="例) A (注文番号の先頭文字) "
            persistent-placeholder
            outlined
            :rules="rules.orderIdPrefix"
          >
            <template v-slot:label>
              接頭文字<span class="ml-2 red--text">必須</span>
            </template>
          </v-text-field>
        </v-col>
      </v-row>

      <!-- ボタン -->
      <v-row class="mt-16">
        <v-col class="pt-0 pb-0">
          <v-btn
            :disabled="!valid"
            class="primary mt-4"
            large
            block
            @click="onClickRegister"
            >{{ btnName }}</v-btn
          >
        </v-col>
      </v-row>
    </v-form>

    <!-- snackbar -->
    <v-snackbar
      v-model="snack"
      :timeout="snackTimeout"
      :color="snackColor"
      :outlined="snackOutlined"
      right
    >
      {{ snackText }}
    </v-snackbar>

    <!-- dialog -->
    <v-dialog v-model="confirmInfo.show" max-width="400" persistent>
      <v-card>
        <v-card-title>
          営業設定を更新します。<br />よろしいですか？
        </v-card-title>
        <v-card-text class="pb-6 pt-12 text-center">
          <v-btn class="mr-3" text @click="confirmInfo.show = false">
            キャンセル
          </v-btn>
          <v-btn color="primary" text @click="executeUpdate()">
            更新する
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
const _cloneDeep = require("lodash.clonedeep");
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import { createUuid, zeroPadding } from "../../util/commonUtil";
import { BUSINESS_DAY_TYPE } from "../../constants";

const DAY_OF_WEEK_TEXTS = [
  "日曜",
  "月曜",
  "火曜",
  "水曜",
  "木曜",
  "金曜",
  "土曜",
];

// 営業/休業設定 (曜日別) デフォルト値
const DAYS_EVERY_DEFAULT = [
  { dayOfWeek: 0, type: String(BUSINESS_DAY_TYPE.HOLIDAY) },
  { dayOfWeek: 1, type: String(BUSINESS_DAY_TYPE.HOLIDAY) },
  { dayOfWeek: 2, type: String(BUSINESS_DAY_TYPE.HOLIDAY) },
  { dayOfWeek: 3, type: String(BUSINESS_DAY_TYPE.HOLIDAY) },
  { dayOfWeek: 4, type: String(BUSINESS_DAY_TYPE.HOLIDAY) },
  { dayOfWeek: 5, type: String(BUSINESS_DAY_TYPE.HOLIDAY) },
  { dayOfWeek: 6, type: String(BUSINESS_DAY_TYPE.HOLIDAY) },
];

export default {
  name: "BusinessSettings",
  components: {},
  props: {},
  data: () => ({
    // form
    valid: true,
    rules: {
      required: [(v) => !!v || "必須入力です"],
      orderIdPrefix: [
        (v) => !!v || "必須入力です",
        (v) => (v && v.length <= 4) || "4文字以内で入力してください",
      ],
    },

    //
    businessDayTypes: [
      { id: "0", label: "休業" },
      { id: "1", label: "1日1回営業" },
      { id: "2", label: "1日2回営業" },
    ],
    tmpDaysEvery: [],
    tmpDaysTemporary: [],

    //
    orderAcceptStatusSelected: 0,

    btnName: "",
    attrs: [],

    // snackbar
    snack: false,
    snackColor: "",
    snackText: "",
    snackTimeout: 3000,
    snackOutlined: true,

    // dialog
    confirmInfo: {
      show: false,
    },
  }),
  computed: {
    ...mapGetters(["progress", "about", "orderAcceptStatusList"]),
  },
  watch: {
    about: function () {
      this.applyData();
    },
  },
  async mounted() {
    this.init();
  },
  methods: {
    ...mapActions([
      "fetchAbout",
      "fetchDefaultValuesForAbout",
      "updateBusinessDays",
      "updateAbout",
    ]),

    async init() {
      this.fetchAbout();
      this.fetchDefaultValuesForAbout();
      this.btnName = "営業設定を更新する";
    },
    async applyData() {
      console.log("#applyData", this.about);
      const about = this.about;

      const daysEvery = _cloneDeep(about.days_every ?? DAYS_EVERY_DEFAULT);
      this.tmpDaysEvery = this.createTmpDaysEvery(daysEvery);

      const daysTemporary = _cloneDeep(about.days_temporary ?? []);
      this.tmpDaysTemporary = this.createTmpDaysTemporary(daysTemporary);
      // console.log("@tmpDaysTemporary", this.tmpDaysTemporary);
    },
    createTmpDaysEvery(days) {
      const newDays = days.map((day) => {
        day.dayOfWeekText = DAY_OF_WEEK_TEXTS[day.dayOfWeek];
        day.hours = this._createTmpDaysHours(day.hours ?? []);
        return day;
      });
      return newDays;
    },
    createTmpDaysTemporary(days) {
      // console.log("#createTmpDaysTemporary", days);
      const newDays = days.map((day) => {
        day.hours = this._createTmpDaysHours(day.hours ?? []);
        day.date = moment(day.date.toDate()).format("YYYY-MM-DD"); // Timestamp -> Date
        return day;
      });
      return newDays;
    },
    _createTmpDaysHours(hours) {
      let newHours = hours.map((hour) => {
        const startHH = zeroPadding(String(hour.startHH), 2);
        const startMM = zeroPadding(String(hour.startMM), 2);
        const endHH = zeroPadding(String(hour.endHH), 2);
        const endMM = zeroPadding(String(hour.endMM), 2);
        return {
          startHHMM: `${startHH}:${startMM}`,
          endHHMM: `${endHH}:${endMM}`,
        };
      });

      // default
      if (newHours.length === 0) {
        newHours = this._createTmpDaysHoursDefault();
      }
      return newHours;
    },
    _createTmpDaysHoursDefault() {
      const hours = [
        { startHHMM: "09:00", endHHMM: "12:00" },
        { startHHMM: "13:00", endHHMM: "18:00" },
      ];
      return hours;
    },
    async onClickRegister() {
      // 確認ダイアログ表示
      this.confirmInfo = { show: true };
    },
    async executeUpdate() {
      try {
        const daysEvery = this.convertTmpDaysEveryForDb(this.tmpDaysEvery);
        const daysTemporary = this.convertTmpDaysTemporaryForDb(
          this.tmpDaysTemporary
        );

        // validate --> 中断
        const checkResult = this.checkDays(daysEvery, daysTemporary);
        if (!checkResult) {
          return;
        }

        // update (business days)
        console.log("executeUpdate", daysEvery, daysTemporary);
        await this.updateBusinessDays({ daysEvery, daysTemporary });

        // update (about 対象項目のみ)
        const dbData = { business: {} };
        dbData.business.cookingMinutes = this.about.business.cookingMinutes;
        dbData.orderIdPrefix = this.about.orderIdPrefix;

        // console.log("@update", dbData);
        await this.updateAbout({ about: dbData });

        // 再取得
        await this.fetchAbout();

        this.showSnackbar("更新しました");
      } catch (e) {
        console.error(e);
        this.showSnackbar("更新に失敗しました");
      } finally {
        this.confirmInfo = { show: false };
      }
    },
    convertTmpDaysEveryForDb(tmpDaysEvery) {
      const days = tmpDaysEvery.map((day) => {
        let newDay = {};
        newDay.dayOfWeek = day.dayOfWeek;
        newDay.type = day.type;
        newDay.hours = this._createTmpDaysHoursForDb(day.hours);
        return newDay;
      });
      return days;
    },
    convertTmpDaysTemporaryForDb(tmpDaysTemporary) {
      const days = tmpDaysTemporary.map((day) => {
        let newDay = {};
        newDay.id = day.id;
        newDay.tmpId = day.tmpId ?? "";
        newDay.date = moment(day.date, "YYYY-MM-DD").toDate(); // Date
        newDay.type = day.type;
        newDay.hours = this._createTmpDaysHoursForDb(day.hours);
        return newDay;
      });
      return days;
    },
    _createTmpDaysHoursForDb(hours) {
      const newHours = hours.map((hour) => {
        const startHHMM = hour.startHHMM;
        const endHHMM = hour.endHHMM;
        const startHH = Number(startHHMM.substr(0, 2));
        const startMM = Number(startHHMM.substr(3, 2));
        const endHH = Number(endHHMM.substr(0, 2));
        const endMM = Number(endHHMM.substr(3, 2));
        return {
          startHH,
          startMM,
          endHH,
          endMM,
        };
      });
      return newHours;
    },
    checkDays(daysEvery, daysTemporary) {
      // console.log("@checkDays", daysEvery, daysTemporary);

      // 開始/終了チェック (曜日別)
      if (!this._checkDaysStartEnd(daysEvery)) {
        this.showErrorSnackbar("日時を見直してください");
        return;
      }

      // 日付重複チェック (臨時)
      if (!this._checkDaysDuplicate(daysTemporary)) {
        this.showErrorSnackbar("日付が重複しています。見直してください");
        return false;
      }

      // 開始/終了チェック (臨時)
      if (!this._checkDaysStartEnd(daysTemporary)) {
        this.showErrorSnackbar("日時を見直してください");
        return;
      }

      return true; // OK
    },
    _checkDaysStartEnd(days) {
      const errDays = days.filter((day) => {
        const type = Number(day.type);
        const hours = day.hours;

        if (type === BUSINESS_DAY_TYPE.BIZ_ONE_TIME) {
          // 開始1 < 終了1
          return !this._checkHourStartEnd(hours[0]);
        }
        if (type === BUSINESS_DAY_TYPE.BIZ_TWO_TIMES) {
          // - 開始1 < 終了1
          // - 開始2 < 終了2
          // - 開始1 < 開始2
          // - 終了1 < 開始2
          const hoursMix1 = {
            startHH: hours[0].startHH,
            startMM: hours[0].startMM,
            endHH: hours[1].startHH,
            endMM: hours[1].startMM,
          };
          const hoursMix2 = {
            startHH: hours[0].endHH,
            startMM: hours[0].endMM,
            endHH: hours[1].startHH,
            endMM: hours[1].startMM,
          };
          return (
            !this._checkHourStartEnd(hours[0]) ||
            !this._checkHourStartEnd(hours[1]) ||
            !this._checkHourStartEnd(hoursMix1) ||
            !this._checkHourStartEnd(hoursMix2)
          );
        }
        return false;
      });
      // console.log("errDays", errDays);
      return errDays.length == 0;
    },
    _checkHourStartEnd(hour) {
      // console.log("hour", hour);
      try {
        const startHH = zeroPadding(String(hour.startHH), 2);
        const startMM = zeroPadding(String(hour.startMM), 2);
        const endHH = zeroPadding(String(hour.endHH), 2);
        const endMM = zeroPadding(String(hour.endMM), 2);
        const dayText = "2022-01-01";
        const start = `${dayText}T${startHH}:${startMM}+0900`;
        const end = `${dayText}T${endHH}:${endMM}+0900`;
        // return moment(start).isBefore(moment(end));
        const result = moment(start).isBefore(moment(end));
        // console.log("@result", result, moment(start), moment(end));
        return result;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    _checkDaysDuplicate(days) {
      const orgDates = days.map((day) => day.date);
      const duplicateDates = orgDates.filter((target, i, self) => {
        return (
          i !== self.findLastIndex((d) => moment(target).isSame(moment(d)))
        );
      });
      // console.log("duplicateDates", duplicateDates);
      return duplicateDates.length == 0;
    },
    // async executeUpdate() {
    //   const keyDates = this.attrs.map((attr) => attr.keyDate);
    //   // console.log("keyDates", keyDates);
    //   try {
    //     const dbData = this.about;
    //     dbData.orderAcceptStatus = this.orderAcceptStatusSelected;
    //     dbData.keyDates = keyDates;
    //     await this.updateAbout({ about: dbData });

    //     this.showSnackbar("更新しました");
    //   } catch (e) {
    //     console.error(e);
    //     this.showSnackbar("更新に失敗しました");
    //   } finally {
    //     this.confirmInfo = { show: false };
    //   }
    // },
    showSnackbar: function (text) {
      this.snack = true;
      this.snackColor = "success";
      this.snackText = text;
    },
    showErrorSnackbar: function (text) {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = text;
      this.snackOutlined = false;
    },
    addDayTemporary() {
      // 空行追加
      const tmpId = createUuid();
      this.addEmptyDayTemporary(tmpId);
    },
    removeDayTemporary(tmpId) {
      this.tmpDaysTemporary = this.tmpDaysTemporary.filter(
        (item) => item.tmpId != tmpId
      );
    },
    addEmptyDayTemporary(tmpId) {
      // console.log(`addEmptyDayTemporary => ${tmpId}`);
      this.tmpDaysTemporary.push({
        tmpId: `${tmpId}`, // 画面表示用 (DB では利用しない)
        date: moment().startOf("day").format("YYYY-MM-DD"),
        type: "0",
        hours: this._createTmpDaysHoursDefault(),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn.v-size--large {
  font-size: 1.4rem;
}
</style>
