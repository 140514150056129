import { v4 as uuidv4 } from "uuid";

export const createUuid = () => uuidv4();

export const null2Empty = (text) => text ? text : "";

export const zeroPadding = (text, allLength) => {
  if (!text || allLength <= text.length) {
    return text;
  }
  return text.padStart(allLength, "0");
};
